import ABNAMRO from './apcoIdealBankLogos/ABNAMRO.jpg';
import ASNBANK from './apcoIdealBankLogos/ASNBANK.jpg';
import BUNQ from './apcoIdealBankLogos/BUNQ.jpg';
import MONEYOU from './apcoIdealBankLogos/MONEYOU.jpg';
import ING from './apcoIdealBankLogos/ING.jpg';
import KNAB from './apcoIdealBankLogos/KNAB.jpg';
import RABOBANK from './apcoIdealBankLogos/RABOBANK.jpg';
import SNSBANK from './apcoIdealBankLogos/SNSBANK.jpg';
import SNSREGIOBANK from './apcoIdealBankLogos/SNSREGIOBANK.jpg';
import TRIODOSBANK from './apcoIdealBankLogos/TRIODOSBANK.jpg';
import VANLANSCHOT from './apcoIdealBankLogos/VANLANSCHOT.jpg';

let apcoIdealBankDetails = [
    {
        bankCode: 'ABNAMRO',
        bankName: 'Abn Amro',
        image: ABNAMRO
    },
    {
        bankCode: 'ASNBANK',
        bankName: 'Asn Bank',
        image: ASNBANK
    },
    {
        bankCode: 'BUNQ',
        bankName: 'Bunq',
        image: BUNQ
    },
    {
        bankCode: 'MONEYOU',
        bankName: 'Moneyou',
        image: MONEYOU
    },
    {
        bankCode: 'ING',
        bankName: 'ING',
        image: ING
    },
    {
        bankCode: 'KNAB',
        bankName: 'Knab',
        image: KNAB
    },
    {
        bankCode: 'RABOBANK',
        bankName: 'Rabobank',
        image: RABOBANK
    },
    {
        bankCode: 'SNSBANK',
        bankName: 'SNS Bank',
        image: SNSBANK
    },
    {
        bankCode: 'SNSREGIOBANK',
        bankName: 'Regio Bank',
        image: SNSREGIOBANK
    },
    {
        bankCode: 'TRIODOSBANK',
        bankName: 'Triodos Bank',
        image: TRIODOSBANK
    },
    {
        bankCode: 'VANLANSCHOT',
        bankName: 'Van Lanschot',
        image: VANLANSCHOT
    },
];

export default apcoIdealBankDetails;