<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="notEnoughData || apcoDepositError">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
                <p v-else-if="apcoDepositError" class="alert alert-danger mt-5 text-center">{{apcoDepositError}} {{$t('request-unsuccessful')}}</p>
            </template>
            <div v-else-if="showBankSection" class="text-center">
                <h1 class="m-4">{{$t('bank-selection')}}</h1>
                <div class="row">
                    <div class="col-sm-6 col-md-3 mb-4" v-for="bank in bankDetails" :key="bank.bankCode" @click="apcoDeposit(false, bank.bankCode)">
                        <div class="card border-secondary">
                            <img :src="bank.image" class="card-img-top" :alt="bank.bankName">
                            <div class="card-body text-secondary">
                                <p class="card-title"><strong>{{bank.bankName}}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="showLoader">
                <div class="text-center mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
            <div v-else-if="(limitReached && maxDeposit > 0) || showNetellerSection">
                <div class="col-12 text-center" v-if="showNetellerSection"><img src="../../../assets/merchant-logos/neteller.png"></div>
                <div class="col-12 text-center mt-5 mb-5" v-if="accountInvalid || showNetellerSection === false"><font-awesome-icon icon="exclamation-triangle" class="icon red" /></div>
                <form @submit.prevent="submit" novalidate>
                    <email v-if="showNetellerSection" v-on:playerEmail="playerEmail" :email="email" :accountInvalid="accountInvalid" ></email>
                    <limits-amount v-else v-on:playerAmount="playerAmount" :amount="amount" :playerCurrencySymbol="playerCurrencySymbol" :limitReached="limitReached" :minDeposit="minDeposit" :maxDeposit="maxDeposit"></limits-amount>
                    <button type="button" @click="submit(showNetellerSection ? 'email' : 'limits')" class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}">
                        <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                        <div v-else class="d-flex align-items-center">
                            <strong>{{$t('loading')}}...</strong>
                            <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    </button>
                </form>
            </div>
            <div v-if="limitReached && maxDeposit <= 0">
                <p class="alert alert-danger mt-3 text-center" v-if="limitReached">{{limitReached}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {getProviderUrl, getCustomerEmail} from "../../../services/backend.service";
    import apcoIdealBankDetails from "../../../assets/apcoIdealBankDetails/apcoIdealBankDetails";

    export default {
        name: 'ApcoDeposit',
        data() {
            return {
                notEnoughData: null,
                apcoDepositError: null,
                showLoader:true,
                paymentOption: null,
                language: null,
                customerId: null,
                ipAddress: null,
                playerCurrency: null,
                playerCurrencySymbol: null,
                brandUrl: null,
                amount: null,
                minDeposit: 5,
                maxDeposit: 100,
                limitReached: null,
                submitStatus: null,
                showBankSection: false,
                bankDetails: apcoIdealBankDetails,
                bankCode: null,
                showNetellerSection: false,
                email: null,
                accountInvalid: null,
            }
        },
        components: {
            Error: () => import('../../others/Error'),
            LimitsAmount: () => import('../../others/LimitsAmount'),
            Email: () => import('../../others/Email'),
        },
        mounted() {
            this.paymentOption = this.$router.history.current.query.paymentOption;
            this.language = this.$router.history.current.query.language;
            this.customerId = this.$router.history.current.query.customerId;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
            this.amount = this.$router.history.current.query.amount;
            this.brandUrl = this.$router.history.current.query.brandUrl;
            this.accountInvalid = this.$router.history.current.query.errorCode;

            if(!this.paymentOption|| !this.ipAddress || !this.playerCurrency || !this.amount || !this.playerCurrencySymbol || !this.customerId){
                this.notEnoughData = true;
            } else if(this.paymentOption === 'IDEAL'){
                this.showBankSection = true;
            } else if(this.paymentOption === 'NETELLER'){
                this.showNetellerScreen();
            } else {
                this.apcoDeposit();
            }
        },
        methods: {
            showNetellerScreen(){
                getCustomerEmail(this.customerId).then(res => {
                    this.showNetellerSection = true;
                    this.showLoader = false;
                    if(res.data.success && res.data.data && res.data.data.email){
                        this.email = res.data.data.email;
                    }
                })
            },
            apcoDeposit(fromSubmit, bankCode) {
                const amount = fromSubmit && this.showNetellerSection === false ? this.amount * 100 : this.amount;
                this.showNetellerSection = false;
                this.bankCode = bankCode !== null && bankCode !== undefined ? bankCode : this.bankCode;
                this.showBankSection = false;
                getProviderUrl({
                    currency: this.playerCurrency,
                    amount: amount,
                    processorName: "APCO",
                    processorType: "EWALLET",
                    walletType : this.paymentOption,
                    customerId: this.customerId,
                    ipAddress: this.ipAddress,
                    language: this.language,
                    brandUrl: this.brandUrl,
                    bankCode: this.bankCode,
                    accountNumber: this.email,
                }).then(res => {
                    this.showLoader = false;
                    this.submitStatus = null;
                    if(res.data && res.data.success && res.data.data && res.data.data.redirectUrl){
                        window.location = res.data.data.redirectUrl;
                    } else if(res.data && res.data.success && res.data.data && res.data.data.minLimitAmount !== null && res.data.data.maxLimitAmount !== null && res.data.data.errorCode && res.data.data.errorCode !== 'account-invalid') {
                        this.minDeposit = Math.floor(res.data.data.minLimitAmount/100);
                        this.maxDeposit = Math.floor(res.data.data.maxLimitAmount/100);
                        this.amount = Math.floor(res.data.data.maxLimitAmount/100);
                        this.limitReached = this.$t(res.data.data.errorCode);
                    } else {
                        this.apcoDepositError = this.$t('error-2');
                    }
                }).catch( e => {
                    this.showLoader = false;
                    this.apcoDepositError = this.$t('error-2');
                    console.log(`%c Error in ApcoDeposit.vue - method: apcoDeposit - ${e}`, 'color:red');
                });
            },
            submit(from) {
                if(from === 'email' && this.email){
                    this.submitStatus = 'PENDING';
                    this.apcoDeposit(true);
                } else if(this.limitReached && this.minDeposit && this.maxDeposit && this.amount){
                    this.submitStatus = 'PENDING';
                    this.apcoDeposit(true);
                }
            },
            playerAmount(value){
                this.amount = value;
            },
            playerEmail(value){
                this.email = value;
            }
        }
    }
</script>
